import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import frontEndImage from "../images/front-end-image.svg"
import backEndImage from "../images/backend.svg"

const WebDev = () => {
  return (
    <Layout>
      <main id="main" style={{ backgroundColor: "rgb(36, 41, 67)" }}>
        <h2 className="text-center text-4xl my-16">Web Development Services</h2>
        <div className="grid grid-cols-3 grid-auto-rows px-16 md:px-32 lg:px-64">
          <div className="col-start-1 col-span-3 grid-row-1 md:col-span-2 row-start-1 my-16 pt-5">
            <section>
              <h2 className="text-2xl font-bold pl-5">Front End Development</h2>
              <p className="text-xl p-5">
                We build web and native apps in a variety of technologies
                selected for their speed, robustness and maintainability.
              </p>
            </section>
          </div>

          <div className="col-start-1 col-span-3 md:col-span-1 row-start-2 md:row-start-1 my-16">
            <img
              src={frontEndImage}
              style={{
                filter:
                  "invert(99%) sepia(68%) saturate(247%) hue-rotate(347deg) brightness(120%) contrast(100%)",
              }}
              className="opacity-75"
            />
          </div>

          <div className="col-start-1 col-span-3 md:col-start-2 row-start-3 md:row-start-2 my-16">
            <section>
              <h2 className="text-2xl font-bold pl-5">Back End Development</h2>
              <p className="text-xl p-5">
                We handle the build and deployment of server side solutions.
                Employing continous integration to deploy fully tested code
                regularly.
              </p>
            </section>
          </div>
          <div className="col-start-1 col-span-3 md:col-span-1 row-start-4 md:row-start-2 my-16">
            <img
              src={backEndImage}
              style={{
                filter:
                  "invert(99%) sepia(68%) saturate(247%) hue-rotate(347deg) brightness(120%) contrast(100%)",
              }}
              className="opacity-75"
            />
          </div>
          <div className="col-start-1 col-span-3 md:col-span-2 my-16">
            <section>
              <h2 className="text-2xl font-bold">Product Development</h2>
              <p className="text-xl">
                We use a multi-disciplinary approach encompassing the following
                methods:
              </p>
              <div className="w-full flex ">
                <ol className="list-disc list-inside">
                  <li>Agile Development</li>
                  <li>Project Management</li>
                  <li>Wire Framing</li>
                  <li>User Stories</li>
                  <li>Scoping</li>
                  <li>Visual Design</li>
                </ol>
              </div>
            </section>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default WebDev
